<template>
  <paper-layout :style="setSetyleBoddy(bodyStyle)" :idioms="SML.idioms" :language="language" :layout="layout" :models="data" :palette="palette" :paperPermissions="{usersCanEdit:[], usersCanRead:[],groupsCanEdit:[],groupsCanRead:[]}"/>
</template>
<script>
//AWS
import { smd_getRecord, smd_getDashData, smd_listEntity, smd_getWebPage } from '../graphql/queries'

//Tools
import Vuex from 'vuex'
import { API, Storage, Auth } from 'aws-amplify'
//Components
import PaperLayout from '../components/papers/PaperLayout.vue'
import PaperDisplayer from '../components/papers/PaperDisplayer.vue'
export default {
  name: 'web-site',
  components:{
    PaperLayout,
    PaperDisplayer,
    
  },
  data() {
    return {
      webs:[
        {domain:'dafsd' }
      ],
      layout:'',
      data:'',
      organization: null,
      SML:{},
      language: null,
      palette:[],
      bodyStyle: '',
    }
  },
  created() {
    console.clear()
    
    
    this.setUserWeb()
  },
  methods: {
    ...Vuex.mapMutations(['setPublicOrganization','SetOrganizationData',"setWebSite",'SetUser']),
    ...Vuex.mapActions(['getPublicOrganization']),
    async setUserWeb(){
      Auth.signIn('smd_webuser', '383@JDU45ss#')
      .then((user) => { 
        this.SetUser(user.username)
        this.getLayout()
      })
      .catch((err) => console.log(err));
    },
    async getLayout(){
      console.log('Here begin ALIAS');
      console.time()
      await this.getPublicOrganization();
      console.timeEnd()
      console.log('Here begin GET WEBPAGE');
      console.time()
      console.log(this.publicOrganizationData.publicLayout);
      let config = {};
      let pullData = ''
      try {
        config ={
          orgPK: this.publicOrganization,
          SMLPK: this.publicOrganizationData.publicLayout.PK,
          SMLSK: this.publicOrganizationData.publicLayout.SK
        }
        console.log('Config Get Layout:', config);
        pullData = await API.graphql({
          query: smd_getWebPage,
          variables: config
        });
        this.SML = {}
        pullData = pullData.data.smd_getWebPage
        pullData = JSON.parse(pullData.data)
        console.log('--> getLayoutData: ', pullData);
        console.log('--> getLayoutData: ', JSON.parse(pullData.SML));
        for (let index = 0; index < JSON.parse(pullData.SML).length; index++) {
          const element = JSON.parse(pullData.SML)[index];
          if (element.attributeType == 'L') {
            element.attributeValue =JSON.parse(element.attributeValue)
          }
          this.SML[element.attributeName] =element.attributeValue
        }
        this.language = this.SML.idioms[0]
        console.log(this.SML);
        this.data = pullData.binds
        this.layout = pullData.layout
        this.palette = this.SML.palette
        this.bodyStyle = this.SML.bodyStyle
        this.setWebSite(this.SML)
        document.head.insertAdjacentHTML("beforeend", this.SML.fontScript)
      } catch (error) {
        console.log(error);
      }
      console.timeEnd()
    },
    async getLayoutBinds( layout ){
      console.log('--> getLayoutBinds:',layout.PK +" - "+ layout.SK +" - "+ this.organization);
      try {
        let wo_PullItem = ''
        wo_PullItem = await API.graphql({
          query: smd_getDashData,
          variables: { 
            SMLPK: layout.PK,
            SMLSK: layout.SK,
            ORGPK: layout.PK//this.publicOrganization''
          },
        });
        console.log(wo_PullItem);
        wo_PullItem = wo_PullItem.data.smd_getDashData
        console.log('--> getLayoutBinds: ',wo_PullItem);
        this.data = JSON.parse(wo_PullItem.data)
      } catch (error) {
        console.log(error);
      }
    },
    async getClass(){
      try {
        console.log('smd_listEntity: ');
        let classes = [];
        let cssCode = '';
        let pullData = await API.graphql({
          query: smd_listEntity,
          variables: {
            PK: this.organization,
            active: '1',
            shortEntity: 'CSS',
          },
        });
        pullData = pullData.data.smd_listEntity.data
        console.log(pullData);
        for (let index = 0; index < pullData.length; index++) {
          let tmpClass = {}
          pullData[index].attributes.forEach(element => {
            switch (element.attributeName) {
              case 'name':
                tmpClass.name =element.attributeValue
              break;
              case 'elementType':
                tmpClass.component =element.attributeValue
              break;
              case 'code':
                tmpClass.code = unescape(element.attributeValue)
              break;
            }
          });
          classes.push(tmpClass)
        }
        
        classes.forEach(element => {
          cssCode = cssCode +' '+element.code
        });
        //console.log(classes);
        //console.log(cssCode);
        //Set stley in head <style>
        document.head.insertAdjacentHTML("beforeend", `<style>${cssCode}</style>`)        
      } catch (error) {
        console.log(error);
      }

    },
    setSetyleBoddy(data){
      let palette = this.palette
      let style = data

      let regexp = /\[.+?\]/g;
      let bind = [...style.matchAll(regexp)];
      console.log(bind);

      for (let index = 0; index < bind.length; index++) {
        let parameter = JSON.parse(bind[index][0])
        console.log(palette[parameter[1]]);
        style = style.replace(bind[index][0], palette[parameter[1]])
      }
      console.log(style);
      return style
    },
  },
  computed:{
    ...Vuex.mapState(['organization','user','publicOrganizationData','publicOrganization'])
  },
}
</script>